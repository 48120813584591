import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';
import { validar } from '~/utils/ie';

export const schema = yup
  .object({
    num_cpf: yup.string().when('tipoPessoa', {
      is: (value: any) => {
        if (value === 0) return true;
        return false;
      },
      then: yup.string().notRequired(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    num_cnpj: yup.string().when('tipoPessoa', {
      is: (value: any) => {
        if (value === 1) return true;
        return false;
      },
      then: yup.string().notRequired(),
      // .test((value) => {
      //   // Remove os caracteres especiais e verifica possui 11 ou 14 digitos
      //   value = value?.replace(/\D/g, '');
      //   return value?.length === 14;
      // }),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    num_rg: yup.string().notRequired(),
    num_ie: yup.string().notRequired(),
    // num_ie: yup
    //   .string()
    //   .notRequired()
    //   .when(['tipoPessoa'], {
    //     is: (valuePessoa: any) => {
    //       if (valuePessoa === 1) return false;
    //       return true;
    //     },
    //     then: yup.string().notRequired().nullable(true),
    //     otherwise: yup
    //       .string()
    //       .required()
    //       .test(function (value) {
    //         // chama funcao para validar IE com base no estado escolhido
    //         const estados = this.parent?.estados;

    //         if (!value) return false;

    //         if (!estados) {
    //           return true;
    //         }
    //         if (estados) {
    //           if (!estados.label) return true;
    //           return validar(value, estados.label);
    //         }

    //         return false;
    //       }),
    //   }),
    sexo: yup.object().when('tipoPessoa', {
      is: (value: any) => {
        if (value === 0) return true;
        return false;
      },
      then: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .required(),
      otherwise: yup.object().notRequired().nullable(true),
    }),
    diaFechamento: yup.number().when(['tipoPessoa', 'empresaConvenio'], {
      is: (tipoPessoa: any, empresaConvenio: boolean) =>
        tipoPessoa === 1 && empresaConvenio,
      then: yup.number().min(1).max(31).nullable(true),
      otherwise: yup.number().notRequired().nullable(true),
    }),
    inativo: yup.boolean(),
    name: yup.string().required(),
    email: yup.string().email().notRequired().nullable(true),
    cep: yup.string().required(),
    logradouro: yup.string().required(),
    numero: yup.string().required(),
    bairro: yup.string().required(),
    cidades: yup
      .object()
      .shape({
        value: yup.number().required(),
        ufValue: yup.string().notRequired(),
        label: yup.string().required(),
      })
      .required(),
  })
  .required();

export const limite = yup
  .object({
    finalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    limiteDeCredito: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat <= 999999999;
      }),
    statusPdv: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();

export const autorizado = yup
  .object({
    parentesco: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cpfAutorizado: yup.string().required(),
    nomeAutorizado: yup.string().required(),
  })
  .required();

export const condicao = yup
  .object({
    condicaoFinalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    condicao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    numCondicao: yup.number().required(),
  })
  .required();

export const moduloSchema = yup
  .object({
    cod_modulo: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    flg_ativo: yup.boolean().required(),
    flg_demonstracao: yup.boolean().required(),
    dta_inicio: yup.string().when('flg_demonstracao', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.string().required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    dta_final: yup.string().when('flg_demonstracao', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.string().required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
  })
  .required();
